<template>
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">PARTENAIRES</h3>
                    </div>
                    <div v-html="modele.partenaires" class="card-body">
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">ACTIVITES</h3>
                    </div>
                    <div v-html="modele.activites" class="card-body">
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">OFFRE</h3>
                    </div>
                    <div class="card-body">
                        <div v-html="modele.offre"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">RESSOURCES</h3>
                    </div>
                    <div class="card-body">
                        <div v-html="modele.ressources"></div>
                    </div>
                </div>
            </div>    
            <div class="col-md-4 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">RELATION CLIENT</h3>
                    </div>
                    <div v-html="modele.relation" class="card-body">
                       
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">SEGMENTS CLIENT</h3>
                    </div>
                    <div v-html="modele.segment" class="card-body">
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">CANAUX DE DISTRIBUTION</h3>
                    </div>
                    <div v-html="modele.canaux" class="card-body">
                      
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">STRUCTURE DES COUTS</h3>
                    </div>
                    <div v-html="modele.couts" class="card-body">
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h5 class="card-title">SOURCES DE REVENUS</h5>
                    </div>
                    <div v-html="modele.revenus" class="card-body">
                    </div>
                </div>
            </div>
        </div>
    </div>       
</template>
<script>
export default {
    name:"ModeleEco",
    props: {
        modele:{
            type:Object,
            default:()=>({
                canal:''
            }),
        },
    }

}
</script>
  