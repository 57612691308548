<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>Programmes</li>
                </ol>
                <h2>{{ programme.name }}</h2>
                <div class="d-flex justify-content-between">
                    <a class="btn btn-sm btn-danger text-white" data-bs-toggle="modal" data-bs-target="#cohorteModal" href=""><i class="bi bi-plus-circle"></i> Creer une cohorte</a>
                    
                    <div class="">
                        <div class="dropdown">
                            <a class="dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Actions</a>
                            <ul class="dropdown-menu">
                                <router-link :to="{name:'admin_programme_candidatures', params: {tkn: programme.token}}" class="dropdown-item"> Afficher les Candidatures</router-link>
                                <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#codeModal">Definir un code secret</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <div class="row">
                    <div id="side1" class="col-md-12 col-sm-12" >
                        <div class="card">
                            <div class="card-body">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-4 col-sm-12">
                                            <div style="height: auto; ">
                                                <img style="width: 100%; max-height: 200px;" :src="programme.photo" alt="">
                                            </div>
                                        </div>
                                       
                                        <div class="col-md-8 col-sm-12">
                                            <div class="card bg-light">
                                                <div class="card-body">
                                                    <p>{{ programme.description }}</p>
                                                </div>
                                                <div class="card-footer">
                                                    <ul class="list-inline">
                                                        <li class="list-inline-item badge bg-primary">
                                                            <strong>{{ programme.cohortes.length }} <small>Cohortes</small></strong>
                                                        </li>
                                                        <li class="list-inline-item badge bg-danger">
                                                            <strong>{{ programme.preseeds.length }} <small>Dossiers preseed</small></strong>
                                                        </li>
                                                        <li class="list-inline-item badge bg-warning">
                                                            <strong>{{ programme.seeds.length }} <small>Dossiers seed</small></strong>
                                                        </li>
                                                        <li class="list-inline-item badge bg-success">
                                                            <strong>{{ programme.startups.length }} <small>Dossiers serie A</small></strong>
                                                        </li>
                                                        <li class="list-inline-item badge bg-info">
                                                            <strong>{{ programme.candidatures.length }} <small>Candidatures</small></strong>
                                                        </li>
                                                    </ul>
                                                    <p><strong>VISIBILITE : <span v-if="programme.public" class="badge bg-danger">PUBLIC</span> <span v-else class="badge bg-warning">PRIVE</span></strong></p>
                                                    <p>
                                                        <button @click="unpublish()" v-if="programme.public" class="btn btn-success btn-sm text-white">DEPUBLIER</button>
                                                        <button @click="publish()" v-else class="btn btn-danger btn-sm text-white">PUBLIER</button>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        </div>
                    </div>
                    <div id="side2" style="overflow: scroll" class="col-md-12 col-sm-12">
                        <fieldset v-if="programme.cohortes.length>0">
                            <legend>LISTE DES COHORTES</legend>
                            <div class="row">
                                <div v-for="(cohorte,index) in programme.cohortes" :key="index" class="col-md-4 col-sm">
                                    <router-link :to="{name:'admin_cohorte', params: {tkn: cohorte.token}}">
                                        <div class="card-header">
                                            <h6>{{ cohorte.name }}</h6>
                                        </div>
                                        <div class="card">
                                            <div class="card-bod">
                                                <img :src="cohorte.photo" style="width: 240px; height: 240px" alt="">
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset v-if="programme.preseeds.length>0">
                            <legend>LISTE DES DOSSIERS PRESEED</legend>
                            <div class="row">
                                <div v-for="(dossier,index) in programme.preseeds" :key="index" class="col-md-4 col-sm">
                                    <router-link :to="{name:'admin_preseed', params: {tkn: dossier.token}}">
                                        <div class="card">
                                            <div class="card-header">
                                                <h6>{{ dossier.name }}</h6>
                                            </div>
                                            <div class="card-bod">
                                                <img :src="dossier.photo" style="width: 240px; height: 240px" alt="">
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset v-if="programme.seeds.length>0">
                            <legend>LISTE DES DOSSIERS SEED</legend>
                            <div class="row">
                                <div v-for="(dossier,index) in programme.seeds" :key="index" class="col-md-4 col-sm">
                                    <router-link :to="{name:'admin_preseed', params: {tkn: dossier.token}}">
                                        <div class="card">
                                            <div class="card-header">
                                                <h6>{{ dossier.name }}</h6>
                                            </div>
                                            <div class="card-bod">
                                                <img :src="dossier.photo" style="width: 240px; height: 240px" alt="">
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset v-if="programme.startups.length>0">
                            <legend>LISTE DES DOSSIERS SERIE A</legend>
                            <div class="row">
                                <div v-for="(dossier,index) in programme.startups" :key="index" class="col-md-4 col-sm">
                                    <router-link :to="{name:'admin_preseed', params: {tkn: dossier.token}}">
                                        <div class="card">
                                            <div class="card-header">
                                                <h6>{{ dossier.name }}</h6>
                                            </div>
                                            <div class="card-bod">
                                                <img :src="dossier.photo" style="width: 240px; height: 240px" alt="">
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset v-if="programme.candidatures.length>0">
                            <legend>LISTE DES CANDIDATURES</legend>
                            <div class="row">
                                <div v-for="(candidature,index) in programme.candidatures" :key="index" class="col-md-4 col-sm">
                                       <p>{{ candidature.date }}</p>
                                </div>
                            </div>
                        </fieldset>

                    </div>
                </div>
        </div>
        <div class="modal fade" id="cohorteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <form @submit.prevent="saveCohorte()" method="post">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">NOUVELLE COHORTE</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" id="close" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="form-group">
            <input type="text" class="form-control" placeholder="Nom de la cohorte" v-model="cohorte.name">
        </div>
        <div class="form-group">
            <label for="description">DESCRIPTION</label>
            <textarea  class="form-control" placeholder="Description de la cohorte" v-model="cohorte.description"></textarea>
        </div>
        <div class="form-group">
            <label class="control-label">PHOTO</label>
            <input id="photo" name="imageUri" @change="handlePhoto($event)"  ref="photo" required="required" type="file"  class="form-control">
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-success">Valider</button>
      </div>
    </div>
            </form>
          </div>
        </div>

        <!---- Definir un code secret  -->
        <div class="modal fade" id="codeModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <form @submit.prevent="setCode()" method="post">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Edition du code secret</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" id="code_close" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label class="control-label">CODE</label>
                            <input id="code" name="code" required type="text" placeholder="Saisir le code ici .." v-model="code"  class="form-control">
                        </div>
                    </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-success"><i class="bi bi-save"></i> ENREGISTRER</button>
                </div>
                </div>
            </form>
          </div>
        </div>
    </main>
</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
export default {
    name:"IndexProgramme",
    props: ['tkn'],
    components:{
    },
    data(){
        return {
            user:this.$store.state.auth.user,
            programme:{cohortes:[],preseeds:[],seeds:[],startups:[],candidatures:[]},
            cohorte:{},
            code:'',
            toaster: createToaster({ position:'top-right'}),
        }
    },
    methods:{
        handlePhoto(event){
            this.photo = event.target.files[0];
        },
        async setCode(){
            let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });   
            await axios.post(this.path+'revendeur/programme/code',{code:this.code,programme_id:this.programme.id})
                        .then(()=>{
                            this.reload();
                        })
                        .catch(()=>{
                            this.toaster.error("Cette operation a echoue !!!");
                        })
                        .finally(()=>{
                            loader.hide();
                            document.getElementById('code_close').click();
                        })
        },
        async saveCohorte(){
            let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            const form = new FormData();
            this.cohorte.programme_id = this.programme.id;
            form.append("photo",this.photo);
            form.append("cohorte",JSON.stringify(this.cohorte));    
            await axios.post(this.path+'revendeur/programme/cohorte',form)
                        .then(()=>{
                            this.reload();
                        })
                        .catch(()=>{
                            this.toaster.error("Cette operation a echoue !!!");
                        })
                        .finally(()=>{
                            loader.hide();
                            document.getElementById('close').click();
                        })
        },
        setData(data){
            this.programme = data;
        },
        async load(){
            let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    //onCancel: this.onCancel,
                });
            await axios.get(this.path+'revendeur/programmes/'+this.tkn)
                        .then((res)=>this.setData(res.data))
                        .catch((err)=>console.error(err))
                        .finally(()=>loader.hide());
        },
        async reload(){
            await axios.get(this.path+'revendeur/programmes/'+this.tkn)
                        .then((res)=>{
                            this.setData(res.data)
                            this.toaster.success("Operation effectuee avec succes !!!");
                        })
                        .catch((err)=>console.error(err));
        },

        async publish(){
            let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            await axios.get(this.path+'revendeur/programme/publish/'+this.tkn)
                        .then((res)=>{
                            console.log(res);
                            this.reload();
                        })
                        .catch((err)=>console.error(err))
                        .finally(()=>loader.hide());
        },
        async unpublish(){
            let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            await axios.get(this.path+'revendeur/programme/unpublish/'+this.tkn)
                        .then((res)=>{
                            console.log(res);
                            this.reload();
                        })
                        .catch((err)=>console.error(err))
                        .finally(()=>loader.hide());
        },
    },
    mounted(){
        this.load().then(()=>console.log(this.programme))
        
    }

}
</script>

<style scoped>
    .badge{
        background-color: #3d9970;
        color: #fff;
    }
    .breadcrumbs a{
        color: #3d9970;
        text-decoration: none;
    }
    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        color: var(--bs-nav-pills-link-active-color);
        background-color: #3d9970;
    }
    a {
        color: #3d9970;
        
    }
    .infos p{
        margin-bottom: 2px;
        font-size: smaller;
    }
    .table thead th{
        font-size: smaller;
    }
    fieldset {
        padding: 15px;
        border: 1px #b9b9b9 solid;
        border-radius: 5px;
        margin: 10px 0;
        min-width: 0;
    }
    legend {
        font-weight: 700;
        font-size: 11px;
        font-family: "segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
        color: #000000;
        padding: 5px;
        width: auto;
        display: block;
        max-width: 100%;
        margin-bottom: .5rem;
        line-height: inherit;
        white-space: normal;
    }
</style>