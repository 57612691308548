<template>
    <section class="vh-50">
        <div class="container-fluid h-custom">
            <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col-md-9 col-lg-6 col-xl-5">
                <img src="assets/img/loginbg.png" class="img-fluid"
                alt="Sample image">
            </div>
            <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
                <Form @submit="login" :validation-schema="schema">
                <div class="divider d-flex align-items-center my-4">
                    <p class="text-center fw-bold mx-3 mb-0">Se connecter </p>
                </div>

                <!-- Email input -->
                <div class="form-group form-outline mb-4">
                    <label class="form-label" for="email">Email</label>
                    <Field name="email" type="email" id="email" placeholder="Email" class="form-control" />
                    <ErrorMessage name="email" class="error-feedback" />
                </div>

                <!-- Password input -->
                <div class="form-group form-outline mb-3">
                    <label class="form-label" for="email">Password</label>
                    <Field name="password" id="password" type="password" placeholder="Mot de passe" class="form-control" />
                    <ErrorMessage name="password" class="error-feedback" />
                </div>
                <div class="text-center text-lg-start mt-4 pt-2">
                    <button type="submit" class="btn btn-success" :disabled="loading"
                    style="padding-left: 2rem; padding-right: 2rem;">
                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                        <span>Se connecter</span>
                </button>
                </div>

                </Form>
            </div>
            </div>
        </div>
    </section>
</template>
<script>
    import { Form, Field, ErrorMessage } from "vee-validate";
    import * as yup from "yup";
    import axios from "axios";

    export default {
    name: "LoginPage",
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        const schema = yup.object().shape({
        email: yup.string().required("Email obligatoire!"),
        password: yup.string().required("Mot de passe obligatoire!"),
        });

        return {
            loading: false,
            message: "",
            schema,
        };
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
    },
    methods: {
        async login(user){
            console.log(user);
            this.loading = true;
            axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
            await axios.post(this.path+'auth/login',user).then((resp)=>{
                console.log(resp);
                this.$store.dispatch("auth/login", resp)
                .then(()=>{
                        if(resp.data.user.role_id == 1){
                            window.location.href='/super/dashboard';
                        }

                        if(resp.data.user.role_id == 11){
                            window.location.href='/admin/dashboard';
                        }
                        if(resp.data.user.role_id == 2){
                            window.location.href='/consultant/dashboard';
                        }
                        if(resp.data.user.role_id == 3){
                            window.location.href='/owner/dashboard';
                        }
                        if(resp.data.user.role_id == 7){
                            window.location.href='/advisor/dashboard';
                        }
                        if(resp.data.user.role_id == 5){
                            window.location.href='/company/dashboard';
                        }

                        if(resp.data.user.role_id == 12){
                            window.location.href='/supplier/dashboard';
                        }
                        if(resp.data.user.role_id == 13){
                            window.location.href='/cga/dashboard';
                        }
                    
                    })
                 .catch(()=>alert('Erreur de connexion au serveur!!'))   

            })
            .catch(()=>{
                alert('Erreur de connexion au serveur!!')
                this.loading = false;
            }); 
        },    
        async handleLogin(user) {
            this.loading = true;
            console.log(user)
            let response = this.$store.dispatch("auth/login", user)
            response.then(()=>{
                
            })
        },
    },

    };
</script>
<style scoped>
    .divider:after,
    .divider:before {
    content: "";
    flex: 1;
    height: 1px;
    background: #eee;
    }
    .h-custom {
    height: calc(100% - 73px);
    }
    @media (max-width: 450px) {
    .h-custom {
        height: 100%;
    }
    }
</style>

