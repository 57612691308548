<template>
<main id="main">

<!-- ======= Breadcrumbs ======= -->
<section id="breadcrumbs" class="breadcrumbs">
  <div class="container">

    <ol>
      <li><a href="/">ACCUEIL</a></li>
      <li>Contact</li>
    </ol>
    <h2>Formulaire d'Inscription </h2>

  </div>
</section><!-- End Breadcrumbs -->

<!-- ======= Contact Section ======= -->
<section id="contact" class="contact bg-light">
  <div style="max-width:500px;" class="container text-center">
    <div class="card">
      <div class="card-body">
        <div class="m-3 text-center">
            <img :src="logo" class="img-circle" style="width: 120px; height: 120px;" alt="">
            <h4 class="mt-3">INVESTORS CLUB FOR AFRICA</h4>
        </div>
        <div>
        <p>
          Devenez Mentor, Ouvrez le Portes de l'Avenir en Afrique! Investissez Votre Expertise, Votre Expérience et Votre Réseau pour Soutenir les Startups et les PME. Rejoignez le Club IC4A en remplissant le formulaire ci-dessous: 
        </p>
      </div>
      <div class="">
        <form @submit.prevent="submit" role="form">
          <div class="container">
            <div class="form-group">
              <input type="text" name="name" class="form-control" id="name" v-model="content.last_name" placeholder="Nom ..." required>
            </div>
            <div class="form-group">
              <input type="text" name="name" class="form-control" id="name" v-model="content.first_name" placeholder="Prenom ..." required>
            </div>
            <div class="form-group">
              <input type="text" class="form-control" name="phone" v-model="content.phone" placeholder="Telephone ..." required>
            </div>
            <div class="form-group">
              <input type="email" class="form-control" name="email" v-model="content.email" id="email" placeholder="Email ..." required>
            </div>
          </div>
          <div class="text-center"><button class="btn btn-success" type="submit"><i class="bi bi-send"></i> Envoyer</button></div>
        </form>
      </div>
      </div>
    </div>

  </div>
</section><!-- End Contact Section -->

</main><!-- End #main -->
</template>

  <script>

import axios from 'axios';
import { createToaster } from "@meforma/vue-toaster";

export default {
    name: 'ContactComponent',
    data(){
      return {
        //assets:this.$assets,
        logo: require('@/assets/ic4a.jpeg'),
        content:{
          first_name:'',
          last_name:'',
          email:'',
          phone:'',
          profession:'',
        },
        toaster: createToaster({ position:'top-right'}),
        loading: false,

      }
    },
    mounted(){
      //this.logo = this.$assets + '/ic4a.jpeg';
    },
    methods:{
      async submit(){
        let loader = this.$loading.show({
                        container: this.fullPage ? null : this.$refs.formContainer,
                        canCancel: false,
                    });

        await axios.post(this.path+"inscription",this.content,{
          headers: {
            "Content-Type": "application/json"
          },
        })
        .then((res)=>{
          console.log(res);
          this.content.first_name='';
          this.content.last_name='';
          this.content.phone='';
          this.content.email='';
          this.toaster.success("Message envoye !");
        })
        .catch((err)=>console.error(err))
        .finally(()=>loader.hide())
      }
    }
  }
  </script>

<style scoped>
  .info-box{
    display: block;
  }
</style>
