import axios from "axios";
import path from '../services/path'
export default {
    namespaced: true,
    state: {
        token: null,
        user: null,
        entreprise:null,
        company:null,
        supplier:null,
        authenticated:false,
    },
    getters: {
        authenticated(state) {
            return state.authenticated;
        },
        user(state) {
            return state.user;
        },
        entreprise(state){
            return state.entreprise
        },
        company(state){
            return state.company;
        },
        supplier(state){
            return state.supplier;
        },
        token(state){
            return state.token
        }
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token;
        },
        SET_USER(state, data) {
            state.user = data;
        },
        SET_AUTHENTICATED(state,aut){
            state.authenticated = aut;
        },
        SET_ENTREPRISE(state,ent){
            state.entreprise = ent;
        },
        SET_COMPANY(state,ent){
            state.company = ent;
        },
        SET_SUPPLIER(state,ent){
            state.supplier = ent;
        },

    },
    actions: {
        async getUser({commit},token) {
            //let token = resp.data.accessToken;
                commit('SET_TOKEN',token)
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
                localStorage.setItem('token', token);
                axios.get(path.getApi()+'user').then((response)=>{
                        commit('SET_USER', response.data.user);
                        commit('SET_ENTREPRISE', response.data.entreprise);
                        commit('SET_COMPANY', response.data.company);
                        commit('SET_SUPPLIER', response.data.supplier);
                        commit('SET_AUTHENTICATED', true);
                        console.log(response);
                        return response;

                }).catch(()=> {
                    commit('SET_TOKEN', null);
                    commit('SET_USER', null);
                    commit('SET_ENTREPRISE', null);
                    commit('SET_COMPANY', null);
                    commit('SET_SUPPLIER', null);
                    commit('SET_AUTHENTICATED', false);
                });
        },
        async login({commit},res) {
            let token = res.data.accessToken;
                commit('SET_TOKEN',token)
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
                localStorage.setItem('token', token);
                axios.get(path.getApi()+'user').then((response)=>{
                        commit('SET_USER', response.data.user);
                        commit('SET_ENTREPRISE', response.data.entreprise);
                        commit('SET_COMPANY', response.data.company);
                        commit('SET_SUPPLIER', response.data.supplier);
                        commit('SET_AUTHENTICATED', true);
                        console.log(response);
                        return response;

                }).catch(()=> {
                    commit('SET_TOKEN', null);
                    commit('SET_USER', null);
                    commit('SET_ENTREPRISE', null);
                    commit('SET_COMPANY', null);
                    commit('SET_SUPPLIER', null);
                    commit('SET_AUTHENTICATED', false);
                });

        },
        async attempt({ commit, state }, token) {
            if (token) {
                commit('SET_TOKEN', token)
            }
            if (!state.token) {
                return
            }
            //header will be added by subcriber
            try {
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
                localStorage.setItem('token', token);
               let response = await axios.get(path.getApi()+'user');
                console.log(response)
                commit('SET_USER', response.data.user);
                commit('SET_ENTREPRISE', response.data.entreprise);
                commit('SET_COMPANY', response.data.company);
                commit('SET_SUPPLIER', response.data.supplier);
                commit('SET_AUTHENTICATED', true);
            } catch (e) {
                commit('SET_TOKEN', null);
                commit('SET_USER', null);
                commit('SET_ENTREPRISE', null);
                commit('SET_COMPANY', null);
                commit('SET_SUPPLIER', null);
                commit('SET_AUTHENTICATED', false);
            }

            return;

        },
        async signOut({ commit }) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${this.state.token}`;
            return axios.post(path.getApi()+'auth/logout').then(() => {
                commit('SET_USER', null)
                commit('SET_TOKEN', null)
                commit('SET_ENTREPRISE', null)
                commit('SET_COMPANY', null)
                commit('SET_SUPPLIER', null)
                commit('SET_AUTHENTICATED', false);
                localStorage.removeItem('token');
            }).catch((err)=>{
                console.log(err);
                this.$router.push({path:'/'});
            }).finally(()=>{
                commit('SET_USER', null)
                commit('SET_TOKEN', null)
                commit('SET_ENTREPRISE', null)
                commit('SET_COMPANY', null)
                commit('SET_SUPPLIER', null)
                commit('SET_AUTHENTICATED', false);
            });
        },


    }
}
