// Composants du Super Admin
import SuperConsultantIndex from "@/components/Super/Consultants/Index.vue"
import SuperPorteurIndex from "@/components/Super/Porteurs/Index.vue"
import SuperPorteurShow from "@/components/Super/Porteurs/Show.vue"
import SuperPreseedIndex from "@/components/Super/Preseeds/Index.vue"
import SuperPreseedShow from '@/components/Super/Preseeds/Show.vue'
import SuperSeedIndex from "@/components/Super/Seeds/Index.vue"
import SuperSeedShow from '@/components/Super/Seeds/Show.vue'
import SuperRechargesIndex from '@/components/Super/Credits/Recharges.vue';
import SuperAchatsIndex from '@/components/Super/Credits/Achats.vue';
import SuperProgrammesIndex from '@/components/Super/Programmes/Index.vue';
import SuperProgrammesShow from '@/components/Super/Programmes/Show.vue';
import SuperProgrammesCohorte from '@/components/Super/Programmes/Cohorte.vue';
import SuperCandidaturesIndex from '@/components/Super/Programmes/Candidatures.vue'
import SuperDashboard from '@/components/Super/Dashboard.vue'

export default[
            //Routes du super Administrateur
            {
                name: "super_dashboard",
                path: '/super/dashboard',
                component: SuperDashboard,
                meta: {
                    title: `Admin Dashboard`,
                    middleware: "auth"
                }
            },
            {
                name: "super_consultant_index",
                path: '/super/consultants',
                component: SuperConsultantIndex,
                meta: {
                    title: `Gestion des consultants`,
                    middleware: "auth"
                }
            },
            {
                name: "super_porteur_index",
                path: '/super/entrepreneurs',
                component: SuperPorteurIndex,
                meta: {
                    title: `Gestion des entrepreneurs`,
                    middleware: "auth"
                }
            },
            {
                name: "super_porteur",
                path: '/super/entrepreneur/:tkn',
                component: SuperPorteurShow,
                props: true,
                meta: {
                    title: `Entrepreneur`,
                    middleware: "auth"
                }
            },
            {
                name: "super_preseed_index",
                path: '/super/preseeds',
                component: SuperPreseedIndex,
                meta: {
                    title: `Gestion des dossiers preseed`,
                    middleware: "auth"
                }
            },
            {
                name: "super_preseed",
                path: '/super/preseed/:tkn',
                component: SuperPreseedShow,
                props: true,
                meta: {
                    title: `Preseed`,
                    middleware: "auth"
                }
            },
            {
                name: "super_seed_index",
                path: '/super/seeds',
                component: SuperSeedIndex,
                meta: {
                    title: `Gestion des dossiers seed`,
                    middleware: "auth"
                }
            },
            {
                name: "super_seed",
                path: '/super/seed/:tkn',
                component: SuperSeedShow,
                props: true,
                meta: {
                    title: `Seed`,
                    middleware: "auth"
                }
            },
            {
                name: "super_preseed_plan",
                path: '/super/preseed/:tkn',
                component: SuperPreseedShow,
                props: true,
                meta: {
                    title: `Preseed`,
                    middleware: "auth"
                }
            },
        
            {
                name: "super_recharges_index",
                path: '/super/recharges',
                component: SuperRechargesIndex,
                meta: {
                    title: `Historique des recharges`,
                    middleware: "auth"
                }
            },
        
            {
                name: "super_achats_index",
                path: '/super/achats',
                component: SuperAchatsIndex,
                meta: {
                    title: `Historique des achats`,
                    middleware: "auth"
                }
            },
            {
                name: "super_programme_index",
                path: '/super/programmes',
                component: SuperProgrammesIndex,
                meta: {
                    title: `Gestion des programmes`,
                    middleware: "auth"
                }
            },
            {
                name: "super_programme",
                path: '/super/programme/:tkn',
                component: SuperProgrammesShow,
                props: true,
                meta: {
                    title: `Details sur le programme`,
                    middleware: "auth"
                }
            },
            {
                name: "super_programme_candidatures",
                path: '/super/programme/candidatures/:tkn',
                component: SuperCandidaturesIndex,
                props: true,
                meta: {
                    title: `Listes des candidatures du programme`,
                    middleware: "auth"
                }
            },
            {
                name: "super_cohorte",
                path: '/super/cohorte/:tkn',
                component: SuperProgrammesCohorte,
                props: true,
                meta: {
                    title: `Details sur la cohorte`,
                    middleware: "auth"
                }
            },
            /* ------------- End of Super Admin section ----------*/
        
]