<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>Business Analysts</li>
                </ol>
                <h2>GESTION DES CONSULTANTS</h2>
                <div style="">
                    <button class="btn btn-success btn-sm mb-2" data-bs-toggle="modal" data-bs-target="#ownerModal">Creer un consultant</button>
                </div>
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <table class="table table-sm table-bordered">
                <thead>
                    <tr>
                        <th>NOM</th>
                        <th>TELEPHONE</th>
                        <th>EMAIL</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(consultant,index) in consultants" :key="index">
                        <td>
                            {{ consultant.first_name }} {{ consultant.last_name }}
                        </td>
                        <td>{{ consultant.phone }}</td>
                        <td>{{ consultant.email }}</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </main>
    <div class="modal fade" id="ownerModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <form @submit.prevent="saveOwner()" method="post">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">NOUVEAU CONSULTANT</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" id="close" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="form-group">
            
            <input type="text" class="form-control" placeholder="Nom" v-model="owner.last_name">
        </div>
        <div class="form-group">
            <input type="text" class="form-control" placeholder="Prenom" v-model="owner.first_name">
        </div>
        <div class="form-group">
            <input type="text" class="form-control" placeholder="Adresse" v-model="owner.address">
        </div>
        <div class="form-group">
            <input type="text" class="form-control" placeholder="Telephone" v-model="owner.phone">
        </div>
        <div class="form-group">
            <input type="text" class="form-control" placeholder="Email" v-model="owner.email">
        </div>
        <div class="form-group">
            <input type="password" class="form-control" placeholder="Mot de passe" v-model="owner.password">
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-success">Valider</button>
      </div>
    </div>
    </form>
  </div>
</div>
</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
export default {
    name:"IndexConsultant",
    data(){
        return {
            user:this.$store.state.auth.user,
            owner:{},
            toaster: createToaster({ position:'top-right'}),
            consultants:[]
        }
    },
    methods:{
        async reload(){
            await axios.get(this.path+'super/consultants')
                        .then((res)=>{
                        // this.setData(res.data)
                        this.consultants=res.data
                           console.log(res.data);
                            this.toaster.success("Operation effectuee avec succes !!!");
                        })
                        .catch((err)=>console.error(err));
        },
        async saveOwner(){
            let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            await axios.post(this.path+'super/consultants',this.owner)
                        .then(()=>{
                            this.reload();
                        })
                        .catch(()=>{
                            this.toaster.error("Cette operation a echoue !!!");
                        })
                        .finally(()=>{
                            loader.hide();
                            document.getElementById('close').click();
                        })
        },
        async load(){
            await axios.get(this.path+'super/consultants')
                        .then((res)=>this.consultants=res.data)
                        .catch((err)=>console.error(err));
        }
    },
    mounted(){
        this.load().then(()=>console.log(this.consultants))
        
    }

}
</script>