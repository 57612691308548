<template>
<main id="main">

<!-- ======= Breadcrumbs ======= -->
<section id="breadcrumbs" class="breadcrumbs">
  <div class="container">

    <ol>
      <li><a href="/">ACCUEIL</a></li>
      <li>Contact</li>
    </ol>
    <h2>Contact</h2>

  </div>
</section><!-- End Breadcrumbs -->

<!-- ======= Contact Section ======= -->
<section id="contact" class="contact">
  <div class="container">

    <div class="row">
      <div class="col-lg-6">
        <div class="info-box mb-4">
          <i class="bx bx-map"></i>
          <h3>Notre Adresse</h3>
          <p>11 Rue Mandou, zone industrielle Pointe-Noire, Republique du Congo</p>
        </div>
      </div>

      <div class="col-lg-3 col-md-6">
        <div class="info-box  mb-4">
          <i class="bx bx-envelope"></i>
          <h3>Ecrivez nous</h3>
          <p>contact@investorsclub4africa.org</p>
        </div>
      </div>

      <div class="col-lg-3 col-md-6">
        <div class="info-box  mb-4">
          <i class="bx bx-phone-call"></i>
          <h3>TEL</h3>
          <p>+242 06 970 48 98</p>
        </div>
      </div>

    </div>

    <div class="row">
      
      <div class="col-lg-6 ">
        <iframe class="mb-4 mb-lg-0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3709.676526352056!2d11.85639068483781!3d-4.781551715734248!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1a60bacf3d6a0b0d%3A0x58065dfae7757730!2sLyc%C3%A9e%20Technique%20Poaty%20Bernard%2C%20Bd%20F%C3%A9lix%20Tchikaya%2C%20Pointe-Noire%2C%20R%C3%A9publique%20du%20Congo!5e0!3m2!1sfr!2scm!4v1696376600003!5m2!1sfr!2scm" frameborder="0" style="border:0; width: 100%; height: 384px;" allowfullscreen></iframe>
      </div>

      <div class="col-lg-6">
        <form @submit.prevent="submit" role="form" class="php-email-form">
          <div class="row">
            <div class="col-md-6 form-group">
              <input type="text" name="name" class="form-control" id="name" v-model="content.name" placeholder="Votre Nom" required>
            </div>
            <div class="col-md-6 form-group mt-3 mt-md-0">
              <input type="email" class="form-control" name="email" v-model="content.email" id="email" placeholder="Votre Email" required>
            </div>
          </div>
          <div class="form-group mt-3">
            <input type="text" class="form-control" name="subjet" v-model="content.suject" id="subject" placeholder="Subjet" required>
          </div>
          <div class="form-group mt-3">
            <textarea class="form-control" name="message" rows="5" v-model="content.message" placeholder="Message" required></textarea>
          </div>
         
          <div class="text-center"><button type="submit">Envoyer</button></div>
        </form>
      </div>

    </div>

  </div>
</section><!-- End Contact Section -->

</main><!-- End #main -->
</template>

  <script>

import axios from 'axios';
import { createToaster } from "@meforma/vue-toaster";

export default {
    name: 'ContactComponent',
    data(){
      return {
        content:{
          message:'',
          suject:'',
          name:'',
          email:'',
        },
        toaster: createToaster({ position:'top-right'}),
        loading: false,

      }
    },
    methods:{
      async submit(){
        let loader = this.$loading.show({
                        container: this.fullPage ? null : this.$refs.formContainer,
                        canCancel: false,
                    });

        await axios.post(this.path+"ic4a/contact",this.content,{
          headers: {
            "Content-Type": "application/json"
          },
        })
        .then((res)=>{
          console.log(res);
          this.content.name='';
          this.content.email='';
          this.content.message='';
          this.content.suject='';
          this.toaster.success("Message envoye !");
        })
        .catch((err)=>console.error(err))
        .finally(()=>loader.hide())
      }
    }
  }
  </script>

<style scoped>
  .info-box{
    display: block;
  }
</style>
