<template>
    <main class="main">
        <div class="container">
            <div class="row">
                    <div id="side2" style="overflow: scroll" class="col-md-12 col-sm-12">
                        <div class="">
                            <div class="">
                            <div>
                                <div>
                                    <fieldset>
                                        <legend>DIAGNOSTIC</legend>
                                        <Diagnostic :choices="choices" />
                                    </fieldset>
                                    <fieldset>
                                        <legend>MODELE ECONOMIQUE</legend>
                                        <modele-economique :modele="dossier.modele"></modele-economique> 
                                    </fieldset>
                                    <fieldset>
                                        <legend>PRESENTATION DE LA PROBLEMATIQUE</legend>
                                        <div v-html="dossier.presentation_problematique"></div>
                                        <div class="table-responsive">
                                            <table class="table table-sm table-striped table-bordered">
                                                <thead>
                                                    <th>#</th>
                                                    <th>Qui sont les clients cibles ?</th>
                                                    <th>Quelle est la problématique à laquelle ils sont confrontés?</th>
                                                    <th>Où achètent-ils des produits-services pour résoudre cette problématique ?</th>
                                                    <th>A quelle fréquence achètent-ils ces produits-services ?</th>
                                                    <th>En moyenne, à combien achètent ils ces produits-services ?</th>
                                                    <th>Pourquoi achètent-ils ces produits-services en particulier et pas un autre ?</th>
                                                </thead>
                                                <tbody>
                                                        <tr v-for="(segment, index) in dossier.segments" :key="index">
                                                            <td>Segment {{ index+1 }}</td>
                                                            <td>{{ segment.clients }}</td>
                                                            <td>{{ segment.problematique }}</td>
                                                            <td>{{ segment.approvisionnement }}</td>
                                                            <td>{{ segment.frequence }}</td>
                                                            <td>{{ segment.moyenne }}</td>
                                                            <td>{{ segment.motif }}</td>
                                                        </tr>
                                                </tbody>
                                        </table>
                                        </div>
                                    </fieldset>
                                    
                                    <hr>
                                        <div>
                                            <fieldset>
                                                <legend>PRESENTATION DE LA SOLUTION</legend>
                                                <div v-html="dossier.presentation_solution"></div>
                                            </fieldset>
                                        </div>
                                        <hr>
                                        <div>
                                           <fieldset>
                                                <legend>TAILLE DU MARCHE</legend>
                                                <div v-html="dossier.presentation_taille_marche"></div>
                                           </fieldset>

                                        </div>
                                        <hr>
                                        <div>
                                            <fieldset>
                                                <legend>CONCURRENCE</legend>
                                                <div v-html="dossier.presentation_concurrence"></div>
                                                <div class="table-responsive">
                                                    <table class="table table-sm table-striped table-bordered">
                                                        <thead>
                                                            <th>#</th>
                                                            <th>Qui sont vos concurrents sur le segment que vous avez ciblé?</th>
                                                            <th>Quel est le produit-service proposé par vos concurrents pour résoudre les problèmes auxquels vos clients sont confrontés? (Avantages et inconvénients)</th>
                                                            <th>Quels sont les canaux de distribution utilisés par chaque concurrent pour acheminer le produit-service vers les clients? Canaux directs et indirects ; (Avantages et inconvénients)</th>
                                                            <th>Quels sont les canaux de communication utilisés par chaque concurrent pour faire connaitre leur produit-service ? (Avantages et inconvénients)</th>
                                                            <th>Quelle est la stratégie mise en place par les concurrents pour pousser les clients à acheter ? (Avantages et inconvénients)</th>
                                                            <th>Quelle est la stratégie mise en place par les concurrents pour fidéliser les clients ? (Avantages et inconvénients)</th>
                                                            <th>Qui sont les fournisseurs de vos concurrents et donnez en une appréciation en terme de qualité/Coût/Quantité/Délai de livraison ?</th>
                                                            <th>Quelle est la disponibilité des produits-services des concurrents (Saisonnière – toute l’année – dans la limite des niveaux de production) (Avantages et inconvénients)</th>
                                                            <th>Chiffres d'affaires</th>
                                                            <th>Charges variables</th>
                                                            <th>Marge brute</th>
                                                            <th>Charges fixes</th>
                                                            <th>Valeur ajoutée</th>
                                                            <th>Salaires</th>
                                                            <th>EBE</th>
                                                        </thead>
                                                        <tbody>
                                                                <tr v-for="(segment,index) in dossier.concurrents" :key="index">
                                                                    <td>Concurrent {{ index+1 }}</td>
                                                                    <td>{{ segment.concurrent }}</td>
                                                                    <td>{{ segment.produit_service }}</td>
                                                                    <td>{{ segment.canaux_distribution }}</td>
                                                                    <td>{{ segment.canaux_communication }}</td>
                                                                    <td>{{ segment.strategie_client }}</td>
                                                                    <td>{{ segment.strategie_fidelisation }}</td>
                                                                    <td>{{ segment.fournisseurs }}</td>
                                                                    <td>{{ segment.disponibilite }}</td>
                                                                    <td>{{ segment.ca }}</td>
                                                                    <td>{{ segment.cv }}</td>
                                                                    <td>{{ segment.mb }}</td>
                                                                    <td>{{ segment.cf }}</td>
                                                                    <td>{{ segment.va }}</td>
                                                                    <td>{{ segment.salaires }}</td>
                                                                    <td>{{ segment.ebe }}</td>
                                                                </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <hr>
                                        <div>
                                            <fieldset>
                                                <legend>VALEUR AJOUTEE</legend>
                                                <div v-html="dossier.presentation_valeur_ajoutee"></div>
                                            </fieldset>
                                        </div>
                                        <hr>
                                        <div>
                                            <fieldset>
                                                <legend>EQUIPE</legend>
                                                <div v-html="dossier.presentation_equipe"></div>
                                            <div v-if="dossier.presentation_equipe" class="pt-2">
                                                
                                                <div class="row">
                                                    <div v-for="(membre, index) in dossier.membres" :key="index" class="col-md-3 col-sm-12">
                                                        <div class="card">
                                                            <div class="card-body">
                                                                <img :src="membre.photo" style="width:180px; height: 120px;" class="img-fluid" alt="">
                                                                <h5>{{ membre.name }}</h5>
                                                                <p>{{ membre.fonction }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </fieldset>
                                        </div>
                                        <hr>
                                        <div>
                                           <fieldset>
                                                <legend>ETAPES FRANCHIES</legend>
                                                <div v-html="dossier.presentation_etapes_realisation"></div>
                                           </fieldset>
                                        </div>
                                        <hr>
                                        <div>
                                            <fieldset>
                                                <legend>OBJECTIFS</legend>
                                                <div v-html="dossier.presentation_objectifs"></div>
                                            </fieldset>
                                        </div>
                                        <hr>
                                        <div>
                                            <fieldset>
                                                <legend>PLAN MARKETING</legend>
                                                <div v-html="dossier.presentation_marketing"></div>
                                                <table class="table table-sm table-striped table-bordered mt-2">
                                                    <thead>
                                                        <th>#</th>
                                                        <th>GAMME DE PRODUIT</th>
                                                        <th>Prix proposé</th>
                                                        <th>Stratégie de distribution</th>
                                                        <th>Stratégie de communication</th>
                                                    </thead>
                                                    <tbody>
                                                            <tr v-for="(segment, index) in dossier.marketings" :key="index">
                                                                <td>Segment {{ index }}</td>
                                                                <td>{{ segment.gamme }}</td>
                                                                <td>{{ segment.prix }}</td>
                                                                <td>{{ segment.distribution }}</td>
                                                                <td>{{ segment.communication }}</td>
                                                            </tr>
                                                    </tbody>
                                                </table>
                                            </fieldset>
                                        </div>
                                        <hr>
                                        <div class="mb-1" id="planfinancier">
                                            <fieldset>
                                                <legend>PLAN FINANCIER</legend>
                                                <div class="row">
                                                <div v-if="dossier.resultat" class="col-md-4 col-sm-12">
                                                    <a :href="dossier.resultat">Compte d'exploitation previsionnel</a>
                                                </div>
                                                <div v-if="dossier.bilan" class="col-md-4 col-sm-12">
                                                    <a :href="dossier.bilan">Bilan previsionnel</a>
                                                </div>
                                                <div v-if="dossier.treso" class="col-md-4 col-sm-12">
                                                    <a :href="dossier.treso">Flux de tresorerie previsionnel</a>
                                                </div>
                                            </div>
                                            </fieldset>
                                        </div>
                                        <hr>
                                        <div>
                                            <fieldset>
                                                <legend>BESOINS FINANCIERS</legend>
                                                <div v-html="dossier.presentation_besoins_financiers"></div>
                                            </fieldset>
                                        </div>
                                        <hr>
                                        <div>
                                            <fieldset>
                                                <legend>PITCHS</legend>
                                                <div class="d-flex gap-3 justify-center text-bold">
                                                    <div v-if="dossier.audio_fr_uri!=null">
                                                        <a :href="getAudioFrPath">En francais</a>
                                                    </div>
                                                    <div v-if="dossier.audio_en_uri!=null">
                                                        <a :href="getAudioEnPath">En anglais</a>
                                                    </div>
                                                </div>
                                           </fieldset>
                                        </div>
                                    <div>
                                    </div>
                                </div>
                  
                            </div>
                            </div>
                            <!-- /.card -->
                        </div>
                    </div>
                </div>
        </div>
    </main>

</template>
<script>
import Diagnostic from "./Diagnostic.vue";
import ModeleEconomique from "./ModeleEconomique.vue";
export default {
    name:"IndexPreseed",
    props: {
        dossier:{
            type:Object,
            default:()=>({
                canal:''
            }),
        },
        choices:{
                'type':Object,
                'required':true
            },
    },
    components:{
        Diagnostic,
        ModeleEconomique,
    },
    data(){
        return {
        }
    },
    methods:{
    },
    computed:{
        getAudioFrPath(){
            return this.audio_base+this.dossier.audio_fr_uri;
        },
        getAudioEnPath(){
            return this.audio_base+this.dossier.audio_en_uri;
        }
    },

}
</script>

<style scoped>
    .badge{
        background-color: #3d9970;
        color: #fff;
    }
    .breadcrumbs a{
        color: #3d9970;
        text-decoration: none;
    }
    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        color: var(--bs-nav-pills-link-active-color);
        background-color: #3d9970;
    }
    a {
        color: #3d9970;
        
    }
    .infos p{
        margin-bottom: 2px;
        font-size: smaller;
    }
    .table thead th{
        font-size: smaller;
    }
    fieldset {
        padding: 15px;
        border: 1px #b9b9b9 solid;
        border-radius: 5px;
        margin: 10px 0;
        min-width: 0;
    }
    legend {
        font-weight: 700;
        font-size: 11px;
        font-family: "segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
        color: #000000;
        padding: 5px;
        width: auto;
        display: block;
        max-width: 100%;
        margin-bottom: .5rem;
        line-height: inherit;
        white-space: normal;
    }
</style>