<template>
    <table class="table table-bordered table-sm">
        <thead>
            <tr>
                <th>TYPE DE RISQUE</th>
                <th>LIBELLE</th>
                <th>CAUSES</th>
                <th>CONSEQUENCES</th>
                <th>FREQUENCE</th>
                <th>GRAVITE</th>
                <th>CRITICITE BRUTE</th>
                <th>CRITICITE NETTE</th>
            </tr>
        </thead>
        <tbody>
            <template v-for="(risque,index) in risques" :key="index">
                <template v-for="(ligne,ind) in risque[1]" :key="ind">
                    <Risque :info="{'ligne':ligne, 'name':risque[0], 'rowspan':risque[1].length}" />
               </template> 
            </template>
        </tbody>
    </table>
</template> 
<script>
    import axios from 'axios';
    import Risque from './Risque.vue';
    export default{
        props:{
            choices:{
                'type':Object,
                'required':true
            },

        },
        components:{
            Risque
        },
        data(){
            return {
                risques:[]
            }
        },
        name:'DiagnosticComponent',
        computed:{
            
        },
        methods:{
            cb(f,g){
               return parseInt(f) * parseInt(g); 
            },
            cn(f,g,t){
                return parseInt(f)* parseInt(g) *parseFloat(t);
            },
            async loadCarto(){
                //console.log("Les reponses -----------");
                //console.log(this.choices);
             await axios.post(this.staging+'carto',{'choices':this.choices})
                .then((res)=>{
                    this.risques = Object.entries(res.data);
                   // console.log();
                    this.risques.forEach(risque=>{
                        console.log(risque[0]);
                        console.log(risque[1]);
                    });
                })
                .catch(()=>console.error("Erreur de chargement cartographie"))
                }
        },
        watch:{
            choices(newValue,oldValue){
                console.log(oldValue);
                console.log(newValue);
                console.log(this.choices);
                this.loadCarto();
            }
        },
        mounted(){
            
            
        }
    }
</script>   