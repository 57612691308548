<template>
    <div id="main">
        <!-- ======= Breadcrumbs ======= -->
        <section  id="breadcrumbs" class="breadcrumbs">
            <div class="container">

              <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>Profil</li>
              </ol>
              <h2>{{ account.first_name }}  {{ account.last_name }}</h2>

            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <div class="m-5">
                    <h3 class="text-center">MON PROFIL</h3>
                    <div class="card" style="max-width: 500px; margin:20px auto;">
                      <div class="card-body">
                        <div class="">
                            <div class="text-center">
                                <img :src="account.photo" alt="" style="border-radius: 50%; width: 100px; height: 100px;">
                            </div>

                        </div>
                        <div class="form-group">
                            <label for="">NOM</label>
                            <input type="text" v-model="account.last_name"  required placeholder="Nom du porteur de projet" class="form-control">
                        </div>
                        <div class="form-group">
                            <label for="">PRENOM</label>
                            <input type="text" v-model="account.first_name"  required placeholder="Prenom du porteur de projet" class="form-control">
                        </div>
                        <div class="form-group">
                            <label for="">TELEPHONE</label>
                            <input type="text" name="user_phone" v-model="account.phone"  placeholder="Numero de telephone" class="form-control">
                        </div>
                        <div class="form-group">
                            <label for="">EMAIL</label>
                            <input type="email" name="user_email" v-model="account.email" required placeholder="Email" class="form-control">
                        </div>
                        <div class="form-group">
                            <label for="">MOT DE PASSE</label>
                            <input type="password" name="password" v-model="account.password" required placeholder="Mot de passe" class="form-control">
                        </div>

                        <div class="form-group">
                            <label for="">PHOTO</label>
                            <input type="file" class="form-control" name="file" id="file" @change="fileChange">
                        </div>

                        <div class="mt-4">
                            <button  type="submit" @click="submit()" class="btn btn-success"><i class="bi bi-save"></i> ENREGISTRER</button>
                        </div>
                      </div>
                    </div>
                    
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import {mapActions} from 'vuex';
import { createToaster } from "@meforma/vue-toaster";
export default {
    name:"ProfilComponent",
    data(){
        return {
            user:this.$store.state.auth.user,
            file:null,
            account:{
                    first_name:'',
                    last_name:'',
                    phone:'',
                    email:'',
                    password:null,
                    photo:'',
                },
            toaster: createToaster({ position:'top-right'}),    
            processing:false,
        }
    },
    methods:{
        ...mapActions({
            signOut:"auth/signOut"
        }),
        fileChange(event){
            this.file = event.target.files[0];
            console.log(this.file);
        },
        async logout(){
            await axios.post('/api/logout')
            .then(()=>{})

        },

        async submit(){
            let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            let form = new FormData();
            if(this.file!=null){
                form.append("file",this.file);
                console.log("Le fichier est non null");
            }
            form.append("account",JSON.stringify(this.account));  
            await axios.post(this.path+'user/profil',form)
                        .then(()=>{
                            this.toaster.success("Operation effectuee avec succes !!!");
                            this.logout();
                            window.location.href='/';
                        })
                        .catch(()=>{
                            this.toaster.error("Cette operation a echoue !!!");
                        })
                        .finally(()=>{
                            loader.hide();
                        })
        },
    },
    mounted(){
        this.account.first_name = this.user.first_name;
        this.account.last_name = this.user.last_name;
        this.account.phone = this.user.phone;
        this.account.email = this.user.email;
        this.account.photo = this.user.photo;
        this.account.id = this.user.id;
    }

}
</script>

<style scoped>
fieldset{
    padding: 15px;
    border: 1px #b9b9b9 solid;
    border-radius: 5px;
    margin: 10px 0;
}

legend{
    font-weight: 700;
    font-size: 11px;
    font-family: "segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #000000;
    padding: 5px;
    width: auto;
    float: none;
}
</style>
