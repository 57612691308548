import Profil from '@/components/Member/Profil.vue'
import UserProfil from '@/components/Member/UserProfil.vue'

export default[
    {
        name: "profil",
        path: '/profil',
        component: Profil,
        meta: {
            title: `Profil`,
            middleware: "auth"
        }
    },
    {
        name: "user_profil",
        path: '/user/profil',
        component: UserProfil,
        meta: {
            title: `Profil`,
            middleware: "auth"
        }
    },
]