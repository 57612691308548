<template>
    <div class="card text-center">
        <div class="card-header text-center d-flex justify-content-between">
            <router-link :to="{name:getLink, params:{tkn:dossier.token}}">
                <strong style="font-size: smaller;">{{ dossier.name }}</strong>
            </router-link>
            <a @click="remove()" title="Supprimer de la cohorte" class="btn btn-danger btn-sm"><i class="bi bi-trash"></i></a>
        </div>
        <div class="card-body">
            <img :src="dossier.photo" style="width: 100%;" alt="">
        </div>
        <div class="card-footer">
            <div class="d-flex justify-center gap-1">
                <div>
                    <img :src="dossier.owner.photo" class="img-circle" alt="">
                </div>
                <div>
                    <router-link :to="{name:'admin_porteur', params: {tkn: dossier.owner.token}}">
                        <strong style="font-size: smaller;" class="mt-2">{{ dossier.owner.name }}</strong>
                    </router-link>
                </div>
            </div>
        </div>
    </div>    
</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
export default {
    name:"DossierCohorteItem",
    props: {
        dossier:{
            type:Object,
            default:()=>({
                name:''
            }),
        },
        type:{
            type:String,
            default:()=>'',
        },
        cohorte_id:{
            type:Number,
            default:()=>0,
        },
    },
    data(){
        return {
            id:0,
            toaster: createToaster({ position:'top-right'}),
        }
    },
    computed:{
        getLink(){
            if(this.type == 'PRESEED'){
                return 'admin_preseed';
            }
            if(this.type == 'SEED'){
                return 'admin_seed';
            }
            if(this.type == 'STARTUP'){
                return 'admin_startup';
            }
            return '';
        }
    }, 
    methods:{
       async remove(){
        let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            await axios.post(this.path+'revendeur/dossier/cohorte/delete',{dossier_id:this.dossier.id,cohorte_id:this.cohorte_id,type:this.type})
                        .then(()=>{
                            this.$emit('deleted');
                        })
                        .catch(()=>{
                            this.toaster.error("Echec lors de la suppression du dossier de la cohorte!!!");
                        })
                        .finally(()=>{
                            loader.hide();
                        })
        },
    }

}
</script>

<style scoped>
    div.card{
        width:100%;
        height: 320px;
    }

    img.img-circle{
        height:50px;
        width:50px;
    }
</style>
  