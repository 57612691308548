<template>
     <div id="hero" class="hero-image section">
        <div class="hero-text">
            <h1><span style="background-color: #620b0e; padding: 5px 10px;">IC4A </span><span style="background-color: #fff;padding: 5px 10px;" class="text-ic4a">Investors Club for Africa</span></h1>
            <p>
            Faites partie d'une communauté dynamique qui transforme des rêves en réalités.
            Explorez, connectez-vous et investissez dans des initiatives qui façonnent l'Afrique de demain.
        </p>
            <a class="btn-ic4a" href="#contact">Rejoignez nous</a>
        </div>
    </div> 
</template>

<script>

    export default{
        name:'HeroComponent',
        nethods:{
        },
    }
</script>

<style scoped>
    .text-ic4a{
        color: #620b0e;
    }
    #hero p{
        width: 100%;
        font-weight: 700;
        text-justify: auto;
    }
    .btn-ic4a{
        background-color: #620b0e; 
        padding: 10px 20px; 
        border-radius: 20px; 
        width: 400px;
        color: #fff;
        cursor: pointer;
        text-decoration: none;
    }
    #hero{
        height:100vh;
        margin:0;
        width: 100%;
    }
    .hero-image {
    /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./hero.jpg");

    /* Set a specific height */
   

    /* Position and center the image to scale nicely on all screens */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    }

    /* Place text in the middle of the image */
    .hero-text {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
    }
</style>