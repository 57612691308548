<template>
<div>
    <Hero />
    <main id="main">
        <section id="about-us" class="about section odd">
            <div class="section-header">
                <h4 class="section-title">A PROPOS DE NOUS</h4>
            </div>
            <div class="section-body">
                <p>Investors Club for Africa est un réseau d'investisseurs passionnés par le développement économique et social de l'Afrique. Nous connectons des investisseurs avec des projets prometteurs, soutenant une croissance durable à travers le continent.</p>
            </div>
            <div class="section-footer">

            </div>
        </section>

        <section id="member" class="about section even">
            <div class="section-header">
                <h4 class="section-title">DEVENIR MEMBRE</h4>
            </div>
            <div class="section-body">
                <p>Rejoignez une communauté exclusive d'investisseurs influents et bénéficiez d'un accès privilégié à des opportunités d'affaires, à des événements de haut niveau et à des partenariats stratégiques sur les marchés africains.  Que vous soyez un investisseur institutionnel ou privé, le club vous offre une plateforme idéale pour découvrir des projets à fort potentiel qui participent activement à la transformation économique et sociale de l'Afrique. </p>
            </div>
            <div class="section-footer">

            </div>
        </section>

        <section id="programs" class="about section odd">
            <div class="section-header">
                <h4 class="section-title">NOS PROGRAMMES</h4>
            </div>
            <div class="section-body">
                <p>Nous proposons des programmes de formation, des ateliers, et des événements de réseautage pour aider nos membres à naviguer dans les diverses opportunités et à comprendre les dynamiques du marché africain. Nos programmes couvrent des domaines clés comme la gestion des risques, la création de partenariats stratégiques, et l’accès à des financements locaux, tout en vous offrant les ressources nécessaires pour réussir vos opérations. </p>
            </div>
            <div class="section-footer">

            </div>
        </section>

        <section id="events" class="section even">
            <div class="section-header">
                <h4 class="section-title">EVENEMENTS A VENIR</h4>
            </div>
            <div class="section-body">
                <p>Participez à nos prochains événements, conférences et forums pour rencontrer des leaders d’opinion, des décideurs influents et des entrepreneurs qui façonnent l’avenir de l’Afrique.  Nos événements sont une occasion unique de découvrir les dernières tendances économiques et de nouer des relations avec des partenaires clés qui façonnent l’avenir du continent africain. </p>
            </div>
            <div class="section-footer">

            </div>
        </section>

        <section id="resources" class="section odd">
            <div class="section-header">
                <h4 class="section-title">RESSOURCES</h4>
            </div>
            <div class="section-body">
                <p>Accédez à nos rapports exclusifs, études de marché, et analyses sectorielles qui vous fourniront les informations nécessaires pour investir en toute confiance en Afrique.</p>
            </div>
            <div class="section-footer">

            </div>
        </section>

        <section id="blog" class="section even">
            <div class="section-header">
                <h4 class="section-title">Blog</h4>
            </div>
            <div class="section-body">
                <p>Découvrez nos derniers articles, études de cas et interviews avec des experts du secteur, mettant en lumière les tendances émergentes et les opportunités d’investissement à travers l’Afrique.</p>
            </div>
            <div class="section-footer">

            </div>
        </section>
        

        <section id="testimonials" class="section odd">
            <div class="section-header">
                <h4 class="section-title">TEMOIGNAGES</h4>
            </div>
            <div class="section-body">
                <p>Lisez les témoignages de nos membres qui partagent leurs expériences et leurs réussites après avoir rejoint Investors Club for Africa.</p>
            </div>
            <div class="section-footer">

            </div>
        </section>

        <section id="contact" class="section odd">
            <div class="section-header">
                <h4 class="section-title">Contactez-nous</h4>
                <p class="section-subtitle">Vous souhaitez en savoir plus sur Investors Club for Africa, nos programmes, ou devenir membre ? Nous serions ravis de répondre à vos questions.</p>
            </div>
            <div class="section-body">
                <div class="d-flex">
                    <div class="w-50 gap-2">
                        <p>Contactez-nous dès aujourd'hui pour discuter de vos projets, découvrir nos opportunités exclusives sur le Continent et dans la Diaspora ou participer à nos événements à venir. Ensemble, nous pouvons contribuer à la transformation de l’Afrique.</p>
                        <div>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3975.932866777153!2d11.8578922!3d-4.7815328!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1a60bacf070840a3%3A0x59a5d851ea37cb29!2s11%20rue%20Mandou%2C%20Pointe-Noire%2C%20Republic%20of%20the%20Congo!5e0!3m2!1sen!2scm!4v1729062762408!5m2!1sen!2scm" height="300" style="border:0; width: 100%;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                    <div class="border border-1 border-white p-2 w-50 ml-2">
                        <form>
                            <div class="">
                                <label for="">NOM</label>
                                <input type="text" class="form-control w-100" placeholder="Votre nom">
                            </div>
                            <div class="form-group">
                                <label for="">TELEPHONE</label>
                                <input type="text" class="form-control w-100" placeholder="Votre numero de telephone">
                            </div>
                            <div class="form-group">
                                <label for="">EMAIL</label>
                                <input type="email" class="form-control w-100" placeholder="Votre adresse email">
                            </div>
                            <div class="form-group">
                                <label for="">MESSAGE</label>
                                <textarea class="form-control w-100" rows="3" placeholder="Saisir votre message ici ..." name="" id=""></textarea>
                            </div>
                            <div class="form-group text-center">
                               <button class="btn-ic4a g-recaptcha"
                                data-sitekey="6LeomlkqAAAAAKG7CsOA1i7lo4mXxr-z9NUv2C7o" 
                                :data-callback='onSubmit()' 
                                data-action='submit'>ENVOYEZ <i class="bx bx-send fs-5 me-2"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="section-footer">

            </div>
        </section>
    </main><!-- End #main -->
</div>
</template>

  <script>

  import Hero from "./Includes/Hero.vue"
  export default {
    name: 'HomeComponent',
    data(){
        return{
            site_key:'6LeomlkqAAAAAKG7CsOA1i7lo4mXxr-z9NUv2C7o'
        }
    },
    components: {
        Hero,
    },
    methods:{
        onSubmit(response){
            console.log(response);
        },
        onVerify(response) {
        // Verify the response with your server and submit the form
        console.log(response)
        }
    },
    mounted(){
        let recaptchaScript = document.createElement("script");
        recaptchaScript.setAttribute(
        "src",
        "https://www.google.com/recaptcha/enterprise.js?render=" + this.site_key
        );
        recaptchaScript.setAttribute("async", "true");
        recaptchaScript.setAttribute("defer", "true");
        document.head.appendChild(recaptchaScript);
    }
  }
  </script>

<style scoped>
    .section{
        min-height: 100vh;
        background-color: #fff;
        margin: 1px;
        padding: 2rem;
        
    }

   /* .grecaptcha-badge {
        visibility: hidden;
    }*/

    .btn-ic4a{
        background-color: #620b0e; 
        padding: 10px 20px; 
        border-radius: 20px; 
        width: 300px;
        color: #fff;
        cursor: pointer;
        text-decoration: none;
    }

    .section-body{
        max-width:80%;
        margin: 2px auto;
        font-size: 0.9rem;
    }

    .form-group{
        margin-top: 1rem;
    }

    label{
        font-size: 0.7rem;
    }

    .section-header{
        padding-top: 75px;
        max-width:80%;
        text-align: center;
        margin: 0 auto 2rem auto;
    }

    .section-title:after{
        display:block;
        clear:both;
        content : "";
        position: relative;
        left    : 0;
        bottom  : 0;
        max-width:150px;
        height  : 1px;
        width   : 50%;  /* or 100px */
        border-bottom:5px solid #620b0e;
        margin:0 auto;
        padding:4px 0px;
    }
    .section-title{
        padding-bottom: 10px;
    }
    
    .odd{
        background: #ededed;
    }

    .even {
        /* The image used */
        background-image: url("./img/parallax.jpg");
        color:#fff;

        /* Create the parallax scrolling effect */
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

   .section-subtitle{
    font-weight: 700;
   }

   #about ul{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        text-align: start;
   }

   #about ul>li{
    padding: 2rem;
   }


</style>
