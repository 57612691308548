
import Home from '@/components/Front/Home.vue'
import Contact from "@/components/Front/Contact.vue"
import Inscription from "@/components/Front/Inscription.vue"
import Login from '@/components/Auth/Login.vue';

export default[
    {
        name: "home",
        path: "/",
        component: Home,
        meta: {
            title: `Accueil`
        }
    },

    {
        name: "contact",
        path: "/contact",
        component: Contact,
        meta: {
            title: `Contact`
        }
    },
    {
        name: "inscription",
        path: "/ic4a/inscription",
        component: Inscription,
        meta: {
            title: `Inscription`
        }
    },
    {
        name: "login",
        path: "/login",
        component: Login,
        meta: {
            middleware: "guest",
            title: `Login`
        }
    },
]