<template>
    <footer id="footer">

    <div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Liens utiles</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a href="/">Accueil</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="/contact">Contact</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="/login">Connexion</a></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Nos Services</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Formation des entrepreneurs</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Structuration de projets</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Levee de fonds</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Gestion du risque</a></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-contact">
            <h4>Contactez nous</h4>
            <p>
              11 Rue Mandou <br>
              Zone industrielle, Pointe-Noire<br>
              Republique du Congo <br><br>
              <strong>Tel:</strong> +242 06 970 48 98<br>
              <strong>Email:</strong> contact@investorsclub4africa.org<br>
            </p>

          </div>

          <div class="col-lg-3 col-md-6 footer-info">
            <h3>A Propos</h3>
            <p>IC4A est une plateforme de structuration de projets et d'accompagnement des poles de gestion des entrepreneurs en Afrique et dans le monde.</p>
            <div class="social-links mt-3">
              <a href="https://www.facebook.com/profile.php?id=100092560654171 " class="facebook"><i class="bx bxl-facebook"></i></a>
              <a href="https://www.linkedin.com/company/91621737/admin/feed/posts/" class="linkedin"><i class="bx bxl-linkedin"></i></a>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="container">
      <div class="copyright">
        &copy; Copyright <strong><span>IC4A</span></strong>. All Rights Reserved
      </div>
    </div>
</footer><!-- End Footer -->

<a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bx bx-abacus"></i></a>

  </template>

  <script>
  export default {
    name: 'FooterComponent'
  }
  </script>

  <style scoped>
      #footer{
        background: #cecece;
      }
      
      .copyright{
        color: #620b0e;
      }
  </style>
