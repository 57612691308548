<template>
        
        <tr>
            <th style="align-content: center; margin-top: auto" align="center" > {{ info.name }}</th>
            <td>{{ info.ligne.question.risque.name  }}</td>
            <td>{{ info.ligne.question.risque.causes  }}</td>
            <td>{{ info.ligne.question.risque.consequences }}</td>
            <td>{{ info.ligne.question.risque.frequence }}</td>
            <td>{{ info.ligne.question.risque.gravite }}</td>
            <td :style="stlb">{{ cb }}</td>
            <td :style="stln" >{{ cn }}</td>
        </tr>
</template> 
<script>
  import { computed } from "vue";
    export default{
        props:{
            info:{
                'type':Object,
                'required':true
            }

        },
        data(){
            return {
                
                frequence:this.info.ligne.question.risque.frequence,
                gravite:this.info.ligne.question.risque.gravite,
                taux:this.info.ligne.taux,
                stlb: computed(() => {
                    let clrb = '#ccc';
                    if(this.cb >= 13){
                        clrb='red';
                    }else{
                        if( parseFloat(this.cb) >=4 && parseFloat(this.cb) <= 12){
                            clrb='yellow';
                        }else{
                            clrb = '#0ac60a';
                        }
                    }
                    return {backgroundColor: clrb,fontWeight: 900,textAlign: 'right'}
                }),
                stln: computed(() => {
                    let clrn = '#ccc';
                    if(this.cn >= 13){
                        clrn='red';
                    }else{
                        if( parseFloat(this.cn) >=4 && parseFloat(this.cn) <= 12){
                            clrn='yellow';
                        }else{
                            clrn = '#0ac60a';
                        }
                    }
                    return {backgroundColor: clrn}
                }),
                cb: computed(()=>{
                    return parseInt(this.frequence) * parseInt(this.gravite);
                }),
                cn: computed(()=>{
                    return this.cb * parseFloat(this.taux);
                })

            }
        },
        name:'RisqueComponent',
        computed:{
            
        },
        methods:{
            
        },
        watch:{
        },
        mounted(){
            console.log(this.info)
            
        }
    }
</script>   