<template>
    <ul>
        <li><a href="#">Accueil</a></li>
        <li><a href="#about-us">A propos de nous</a></li>
        <li><a href="#member">Devenir Membre</a></li>
        <li><a href="#programs">Nos Programmes</a></li>
        <li><a href="#blog">Blog</a></li>
        <li><a href="#contact">Contact</a></li>
    </ul>
</template>
<script>
    export default{
        name:'FrontNavbar', 
        methods:{
            
        }
    }
</script>

<style scoped>
    .navbar{
        font-weight: 800;
    }
</style>