<template>
    <ul>
        <li><router-link to="/admin/dashboard"> ACCUEIL</router-link></li>
            <li class="dropdown">
                <a class="">
                    <div class="text-center">
                        <p class="m-0">DOSSIERS<i class="bi bi-chevron-down"></i></p>
                    </div>
                </a>
                <ul>
                    <li><router-link to="/admin/preseeds">PRESEED</router-link></li>
                    <li><router-link to="/admin/seeds">SEED</router-link></li>
                    <li><router-link to="/admin/serie">SERIE A</router-link></li>
                    <li><router-link to="/admin/pme">PME</router-link></li>
                </ul>
            </li>
            <li><router-link to="/admin/programmes"> PROGRAMMES</router-link></li>
            <li><router-link to="/admin/consultants"> CONSULTANTS</router-link></li>
            <li><router-link to="/admin/entrepreneurs">PORTEURS DE PROJET</router-link></li>
            <li class="dropdown">
                <a class="">
                    <div class="text-center">
                        <p class="m-0">CREDITS<i class="bi bi-chevron-down"></i></p>
                    </div>
                </a>
                <ul>
                    <li><router-link to="/admin/recharges">RECHARGES</router-link></li>
                    <li><router-link to="/admin/achats">ACHATS</router-link></li>
                </ul>
            </li>
            <div style="display: inherit;" v-if="authenticated">
            <li class="dropdown">
                <a class="">
                    <div class="text-center user-profil">
                        <i style="font-size:25px;" class="bi bi-person"></i><i class="bi bi-chevron-down"></i>
                       
                    </div>
                </a>
                <ul>
                    <li><router-link to="/user/profil">Profil Utilisateur</router-link></li>
                    <li><router-link to="/profil">Profil Incubateur</router-link></li>
                    <li><a class="dropdown-item" href="javascript:void(0)" @click="logout">Se deconnecter</a></li>
                </ul>
            </li>
            </div>
    </ul>
</template>
<script>
    import {mapActions} from 'vuex';
    import axios from "axios";
    export default{
        name:'RevendeurNavbar',
        data: function(){
            return {
                authenticated:this.$store.state.auth.authenticated?this.$store.state.auth.authenticated:false,
            }
        },
        methods:{
            ...mapActions({
                signOut:"auth/signOut"
            }),
            async logout(){
                await axios.post(this.path+'auth/logout').then(()=>{
                    this.signOut()
                    this.authenticated = false;
                    window.location.href="/"
                    //this.$router.push({name:"home"})
                })
            }
        }
    }

</script>
<style scoped>
    .navbar a{
        text-decoration: none;
        font-weight: bolder;
    }

</style>