<template>
    <div id="main">
        <!-- ======= Breadcrumbs ======= -->
        <section  id="breadcrumbs" class="breadcrumbs">
            <div class="container">

              <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>Profil Incubateur</li>
              </ol>
              <h2>{{ entreprise.name }}</h2>

            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <div class="m-5">
                <h3 class="text-center">PROFIL INCUBATEUR</h3>
                <div class="card" style="max-width: 500px; margin:20px auto;">
                    <div class="card-body">
                        <div class="">
                            <div class="form-group">
                                <label for="">NOM</label>
                                <input type="text" name="name" v-model="account.name"  required placeholder="Nom" class="form-control">
                            </div>
                            <div class="form-group">
                                <label for="">TELEPHONE</label>
                                <input type="text" name="phone" v-model="account.phone" required placeholder="Telephone" class="form-control">
                            </div>
                            <div class="form-group">
                                <label for="">Email</label>
                                <input type="email" name="email" v-model="account.email" required placeholder="Email" class="form-control">
                            </div>
                            <div class="form-group">
                            <label for="">LOGO</label>
                            <input type="file" class="form-control" name="file" id="file" @change="fileChange">
                        </div>

                        <div class="mt-4">
                            <button  type="submit" @click="submit()" class="btn btn-success"><i class="bi bi-save"></i> ENREGISTRER</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
export default {
    name:"ProfilComponent",
    data(){
        return {
            user:this.$store.state.auth.user,
            entreprise:this.$store.state.auth.entreprise,
            file:null,
            account:{
                    name:'',
                    phone:'',
                    email:'',
                },
            toaster: createToaster({ position:'top-right'}),
        }
    },
    methods:{
        fileChange(event){
            this.file = event.target.files[0];
            console.log(this.file);
        },
        async logout(){
            await axios.post('/api/logout')
            .then(()=>window.location.href='/')
        },
        async submit(){
            let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            let form = new FormData();
            if(this.file!=null){
                form.append("file",this.file);
                console.log("Le fichier est non null");
            }
            form.append("account",JSON.stringify(this.account));  
            await axios.post(this.path+'entreprise/profil',form)
                        .then(()=>{
                            this.toaster.success("Operation effectuee avec succes !!!");
                            this.logout();
                            window.location.href='/';
                        })
                        .catch(()=>{
                            this.toaster.error("Cette operation a echoue !!!");
                        })
                        .finally(()=>{
                            loader.hide();
                        })
        },
    },
    mounted(){
        this.account.name = this.entreprise.name;
        this.account.email = this.entreprise.email;
        this.account.phone = this.entreprise.phone;
        this.account.id = this.entreprise.id;
    }

}
</script>

<style scoped>
    fieldset{
    padding: 15px;
    border: 1px #b9b9b9 solid;
    border-radius: 5px;
    margin: 10px 0;
}

legend{
    font-weight: 700;
    font-size: 11px;
    font-family: "segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #000000;
    padding: 5px;
    width: auto;
    float: none;
}
</style>
