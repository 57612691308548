const API_URL = process.env.VUE_APP_ROOT_API;

class Path{
    getApi(){
        return API_URL;
    }

    getAuth(){
        return API_URL + 'auth';
    }
}
export default new Path();