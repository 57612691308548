<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>Credit</li>
                </ol>
                <h2>HISTORIQUE DES ACHATS</h2>

            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <table class="table table-sm table-bordered">
                <thead>
                    <tr>
                        <th>DATE</th>
                        <th>MONTANT</th>
                        <th>STATUT</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(recharge,index) in recharges" :key="index">
                        <td>{{ recharge.created }}</td>
                        <td>{{ recharge.montant_formatted }} FCFA</td>
                        <td> <span :class="recharge.statut.color">{{ recharge.statut.name }}</span> </td>
                        
                    </tr>
                </tbody>
            </table>
        </div>
    </main>
</template>
<script>
import axios from "axios";
export default {
    name:"AchatsComponent",
    data(){
        return {
            user:this.$store.state.auth.user,
            recharges:[]
        }
    },
    methods:{
        async load(){
            await axios.get(this.path+'super/achats')
                        .then((res)=>this.recharges=res.data)
                        .catch((err)=>console.error(err));
        }
    },
    mounted(){
        this.load().then(()=>console.log(this.recharges))
        
    }

}
</script>

<style scoped>
    .badge-success{
        background-color: #3d9970;
        color: #fff;
        padding: 5px;
        border-radius: 5px;
        font-size: smaller;
        font-weight: bold;
    }

    .breadcrumbs a{
        color: #3d9970;
        text-decoration: none;
        font-weight: bolder;
    }

    tr{
        font-weight: 600;
    }
</style>