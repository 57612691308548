import './bootstrap';
import 'bootstrap/dist/css/bootstrap.css'
import "./assets/vendor/animate.css/animate.min.css"
import "./assets/vendor/bootstrap/css/bootstrap.min.css"
import "./assets/vendor/bootstrap-icons/bootstrap-icons.css"
import "./assets/vendor/boxicons/css/boxicons.min.css"
import './assets/dist/css/adminlte.css'
import './assets/css/style.css'
import './assets/js/main'
import 'vuetify/lib/styles/main.css'
import '@trevoreyre/autocomplete-vue/dist/style.css'
//fichier css du pdf viewer

 

import { createApp } from "vue";
import App from "./App.vue";
import $ from "expose-loader?exposes=$,jQuery!jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import Router from './route'
import store from '@/store'
import path from './services/path'
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';


import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import VeeValidatePlugin from "./plugins/validation";
import CKEditor from '@ckeditor/ckeditor5-vue';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';

const vuetify = createVuetify({
  components,
  directives,
})



/**
 * Next, we will create a fresh Vue application instance. You may then begin
 * registering components with the application instance so they are ready
 * to use in your application's views. An example is included for you.
 */
var orm = 'https://ormsystem.com/api/';
//var api = 'https://staging.obac-alert.com/api/';

const editorConfig = {
  language: 'fr',
  toolbar: {
      items: [
          'bold',
          'italic',
          'undo',
          'redo',
          'heading',
          '|',
          'alignment', 
          'link',
          'bulletedList',
          'numberedList',
          'blockQuote',
      ]
  }
}

store.dispatch("auth/attempt", localStorage.getItem("token")).then(() => {
   let app = createApp(App)

   app.use(Router)
    app.use(store)
    app.use($)
    app.use(LoadingPlugin)
    app.use(VeeValidatePlugin)
    app.use(vuetify)
    app.use( CKEditor)
    app.use(PrimeVue,{
      // Default theme configuration
      theme: {
          preset: Aura,
          options: {
              prefix: 'p',
              darkModeSelector: 'system',
              cssLayer: false
          }
      }
   });
    
    app.config.globalProperties.path = path.getApi()
    app.config.globalProperties.orm = orm
    app.config.globalProperties.staging = '/orm/'
    app.config.globalProperties.audio_base = 'http://reminder.funding-projects.org/'; 
    //app.config.globalProperties.staging = orm 
    app.config.globalProperties.editorConfig = editorConfig 
    app.config.globalProperties.$assets = "@/assets";
    app.mount('#app')
});
